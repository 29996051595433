import React, { useState } from 'react'
import { useForm } from "../hooks/useForm";
import axios from "axios";
import "../assets/css/formSancor.css"
import "../assets/css/radio.css"

const FormSancor = () => {

    const utms = window.location.search.substring(1).split("&");
    const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
    const utm_medium = utms[1] ? utms[1].split("=").pop() : "Landing";
    const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Sancor";
    const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
    const utm_content = utms[4] ? utms[4].split("=").pop() : "Promo";

    const initialForm = {
      nombre:"",
      email:"",
      telefono:"",
      provincia:"",
      es_cliente:"",
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [emptyError, setEmptyError] = useState({});
    const [formValues, handleInputChange, reset] = useForm(initialForm);

    const validar_email = (email) => {
      return /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)
    }

    const validar_tel = (tel) => {
      return tel.length > 8 && tel.length < 13
    }

    const handleSubmitClick = async (event) => {
        event.preventDefault();
        var err = {}
        for (let k in formValues){
          err = {...err, [k]:(formValues[k]==="")};
        }
        err["email"]=err["email"]||!validar_email(formValues.email)
        err["telefono"]=err["telefono"]||!validar_tel(formValues.telefono)

        setEmptyError(err)
        //console.log(formValues)

        if (Object.values(err).includes(true)){
          return;
        }else {


          let lead = {
            ...formValues,
            utm_medium_c: utm_medium,    
            utm_source_c: utm_source,
            utm_content_c: utm_content,
            utm_campaign_c: utm_campaign,
            utm_term_c: utm_term,
            status: "New",
            assigned_user_id: "821db49c-70f4-8040-d6f9-62ff989b79b5",
          }

          await axios({
            method: "POST",
            url: "processor-sancor.php",
            headers: {
              "Content-Type": "application/json",
            },
            data: lead,
          })
            .then((res) => {
              if (res.data.includes("success")) {
                
                window.fbq('track', 'CompleteRegistration');
                
                //setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
                setCurrentStep((prevStep) => prevStep + 1);
                reset();
                //setLoading(false);
              } else {
                alert("Ha ocurrido un error inesperado!")
                //setCorrectValidation(5);
                //setLoading(false);
              }
            })
            .catch((err) => {
              console.warn(err);
                //setCorrectValidation(5); // "A ocurrido un error inesperado"
                alert("Ha ocurrido un error inesperado")
                //setLoading(false);
            });

          setTimeout(() => {
            setEmptyError({})
            //setCorrectValidation(0);
          }, 4000);
          return;
        }

    };
  return (
      <div className="col-md-8 col-md-offset-3">
        <form id="msform-sancor">
          <fieldset style={{ display: currentStep === 0 ? 'block' : 'none' }}>
            <div className='text-input-container'>

              <input
                required={true}
                className={emptyError.nombre?"error-text":''}
                type="text"
                name="nombre"
                placeholder={emptyError.nombre?"Llene el campo nombre":"Nombre"}
                value={formValues.nombre}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, nombre:formValues.nombre===""})}
                onFocus={() => setEmptyError({...emptyError, nombre:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <input
                required={true}
                className={emptyError.email?"error-text":''}
                type="email"
                name="email"
                placeholder={emptyError.email?"Llene el campo email":"Email"}
                value={formValues.email}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, email:(formValues.email==="")})}
                onFocus={() => setEmptyError({...emptyError, email:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <input
                required={true}
                className={emptyError.telefono?"error-text":''}
                type="number"
                name="telefono"
                placeholder={emptyError.telefono?"Llene el campo teléfono":"Teléfono"}
                value={formValues.telefono}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, telefono:formValues.telefono===""})}
                onFocus={() => setEmptyError({...emptyError, telefono:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <div className='dropdown-container'>
                <select
                  name="provincia"
                  className={emptyError.provincia?"error-text":''}
                  value={formValues.provincia}
                  onChange={handleInputChange}
                  onBlur={() => setEmptyError({...emptyError, nombre:formValues.provincia===""})}
                  onFocus={() => setEmptyError({...emptyError, nombre:false})}
                  required
                  style={{
                    color: formValues.provincia === "" ? "#8f98a1" : "#000",
                  }}
                >
                  <option value="" disabled hidden>Seleccionar Provincia</option>
                  <option value="buenos_aires">Buenos Aires</option>
                  <option value="chaco">Chaco</option>
                  <option value="cordoba">Córdoba</option>
                  <option value="corrientes">Corrientes</option>
                  <option value="mendoza">Mendoza</option>
                  <option value="neuquen">Neuquén</option>
                  <option value="rio_negro">Río Negro</option>
                  <option value="santa_fe">Santa Fe</option>
                  <option value="tucuman">Tucumán</option>
                </select>
                <br/>

              </div>
              <div className='dropdown-container'>
                <select
                  name="es_cliente"
                  className={emptyError.es_cliente?"error-text":''}
                  value={formValues.es_cliente}
                  onChange={handleInputChange}
                  onBlur={() => setEmptyError({...emptyError, nombre:formValues.es_cliente===""})}
                  onFocus={() => setEmptyError({...emptyError, nombre:false})}
                  required
                  style={{
                    color: formValues.es_cliente === "" ? "#8f98a1" : "#000",
                  }}
                >
                  <option value="" disabled hidden>¿Sos cliente de Sancor Seguros?</option>
                  <option value="si_es_cliente">Si soy cliente</option>
                  <option value="no_es_cliente">No soy cliente</option>
                </select>
                <br/>

              </div>
              
            <div className="final-buttons-sancor">
              <button className="submit action-button-sancor" onClick={handleSubmitClick}>Enviar</button>
            </div>

            </div>

          </fieldset>

          <div style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <h4 className='thanks-title'>¡MUCHAS GRACIAS!</h4>
            <h5 className='thanks-subtitle'>HEMOS RECIBIDO CORRECTAMENTE TU SOLICITUD</h5>
            <h6 className='thanks-text'>En breve un asesor se contactará contigo.</h6>
          </div>
        </form>
      </div>
  );
}

export default FormSancor